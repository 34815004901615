import React from 'react'
const IconCaretDown = ({ width, height, fillColor, strokeColor, iconTitle }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby='title'
    >
      <title>{iconTitle}</title>
      <path
        d='M12.5 6.5L8 11L3.5 6.5'
        stroke={strokeColor || '#FFFFFF'}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill = {fillColor || "#fff"}
      />
    </svg>
  )
}
export default IconCaretDown