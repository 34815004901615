import { observerService } from '@/utils/observer'
import { pushDataLayerEventHandler } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { memo, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import css from './Header.module.scss'
import { isAuctionLiveCount } from '@/services/headerService'
import dynamic from 'next/dynamic'
import { useAuctionProvider } from '@/lib/AuctionProvider'

const IconoGraphy = dynamic(()=>import('../IconoGraphy'))
const PrimaryNav = dynamic(()=>import('./PrimaryNav')) 
const SecondaryNav = dynamic(()=>import('./SecondaryNav')) 
const CustomPropertyDetail = dynamic(()=>import('./CustomPropertyDetail'))
const Brand = dynamic(()=>import('./Brand')) 

let importMethods = null
const Header = ({
  locale,
  headerCities,
  page,
  stickyHeader,
  metaData,
  isOpenAppHeader,
  customClass,
  slugQueries = {},
  pageType,
  customDropDown,
  setCustomDropDown,
  auctionDetails,
  getAuctionDetails,
}) => {
  const [ProfileSideMenu, setProfileSideMenu] = useState(null);
  const [LoginPopup, setLoginPopup] = useState(null);
  const [navbarOpen, ] = useState(false)
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [revampNavOpen, setRevampNavOpen] = useState(false)
  const [isSignInClick, setIsSignInClick] = useState(false)
  const [statusTag, setStatusTag] = useState(null);
 
  const { lang } = useTranslation('translations-auction')
  const {isEditPopUpOpen = false} = useAuctionProvider() || {}
  const router = useRouter()

  // Checking if current page is ADP or GDP.
  // Adding condition according to the current page.
  const mobileHeaderStickyPages = ['auction-group-detail', 'auction-detail-revamp']
  const showStickyMobileHeader = mobileHeaderStickyPages.includes(page)

  const isAuctionDetailPage = page === 'auction-detail-revamp'
  const isGroupDetailPage = page === 'auction-group-detail'

  const { query } = router
  const handleNavbar = (currentState,isHamburgerClicked = false) => {
    if(!currentState && isHamburgerClicked)
      setRevampNavOpen(true)
  }
  const onScreenClose = () => setIsSignInClick (false);

  const onSuccessLogin = () =>{
    router.push('/user/login')
  }
  const handleDataLayerCommon = (arg, data = {}) => {
    pushDataLayerEventHandler(window, lang, 'hamburger_clicked', pageType)
    const newData = {...data}
    pushDataLayerEventHandler(window, lang, arg, pageType, newData)
  }
  
  const dynamicImportMethods = async () => {
    if(!importMethods) {
      importMethods = await import(/*webpackChunkName: "Header ImportMethods" */ './Methods/ImportMethods')
    }
  }

  const loadDynamicContent = async () => {
    await dynamicImportMethods()
    if(revampNavOpen && !ProfileSideMenu) {importMethods.importProfileSideMenu({ setProfileSideMenu })}
    if(isSignInClick && !LoginPopup) {importMethods.importLoginPopup({ setLoginPopup })}
  }

  useEffect(() => {
    loadDynamicContent();
  },[revampNavOpen, isSignInClick])

  /**
   * added to handle filter drawer open in mobile view
   */
  useEffect(() => {
    const observer = observerService.observable().subscribe((val) => {
      if (val?.data?.type == 'filterDrawerOpen' && val?.data?.data && isMobileOnly) {
        setFilterDrawerOpen(val?.data?.data?.isOpen)
      }
      window.scrollTo(0, 0)
    })
    return () => observer.unsubscribe
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const liveAuction = await isAuctionLiveCount()
      const upcomingCase = liveAuction[1] > 0 ? "upcoming" : "closed"
      setStatusTag(liveAuction[0] > 0 ? "live"  : upcomingCase )
    }
    fetchData()
  },[])

  const handleDataLayer = (arg) => {
    // Google Tag Manager -- Data Layer Start
    const attrDataLayerValues = {}
    
    const data = {
      Property_id: auctionDetails?.propertyId,
      title: auctionDetails?.title,
      address: auctionDetails?.address,
      city: auctionDetails?.city,
      city_id: auctionDetails?.cityId,
      ...attrDataLayerValues,
    }

    pushDataLayerEventHandler(window, lang, arg, pageType, data)
    //Google Tag Manager -- Data Layer end
  }
  const handleDataLayerForNav=(event, data = {})=>{
    pushDataLayerEventHandler(window, lang, event, page, data)
  }
  const handleBackClick = () => {
    const page_type = isGroupDetailPage ? 'GDP' : 'PDP'
    handleDataLayer(`${auctionDetails?.propIdentityTypeId == 96 ? 'CDP' : page_type}_Back`)
    const previousPath =  query?.[`auction-group-id`] ? "/": (window.localStorage.getItem('prevPath') || '/') // removed isGroupDetailPage as we need to redirect to previous path which may contain filter
    router.push('/'+ previousPath)
    pushDataLayerEventHandler(window, lang, isGroupDetailPage ? 'auction_GDP_SRP_Back' : 'auction_ADP_SRP_Back', isGroupDetailPage ? 'GDP' : 'ADP')
  }

  const getClassName = () => {
    return `
      ${css.header}
      ${page && page === 'home' ? css.home : ''}
      ${page && page === 'home' ? css.homeheader : ''}
      ${page && page === 'auction-home-page' ? css.posInherit : ''}
      ${stickyHeader ? css.sticky : ''}
      ${navbarOpen ? css.navbaropen : ''} 
      ${isOpenAppHeader != null && !isOpenAppHeader ? css.open_app_header : ''}
      ${customClass ? css[customClass] : ''}
      ${filterDrawerOpen ? css.headerRemove : ''} `
  }

  const isSideNavProfileAvailable = () => revampNavOpen && ProfileSideMenu  && !isEditPopUpOpen
  const isLoginPopUpAvailable = () => isSignInClick && LoginPopup && !isEditPopUpOpen

  return (
    <>
    <header
      id='header'
      className={getClassName()}
    >
      <div
        className={`
      ${css.container} 
      ${showStickyMobileHeader ? `${css.mobCustomPdp}` : ''}
      `}
      >
        {
          showStickyMobileHeader && <div
            className={`${css.isNotMobile} ${css.backPage}`}
            onClick={handleBackClick}
          >
            <IconoGraphy iconClass={`icon-arrow-back`} fontSize={'f_12'} customClass={'sideMargin'}></IconoGraphy>
          </div>
        } 
        <PrimaryNav
          locale={locale}
          headerMenuCities={headerCities}
          handleNavbar={handleNavbar}
          navbarOpen={revampNavOpen}
          stickyHeader={stickyHeader}
          page={page}
          metaData={metaData || null}
          isOpenAppHeader={isOpenAppHeader}
          slugQueries={slugQueries}
          pageType={pageType}
          statusTag={statusTag}
          showStickyMobileHeader={showStickyMobileHeader}
        />
        <div
          className={`${css.pdpfixedtext} ${css.isNotMobile} ${
            isAuctionDetailPage && stickyHeader ? '' : css.hide
          }`}
        >
          <h4>{auctionDetails?.title}</h4>
          <span>{auctionDetails?.address}</span>
        </div>
        <Brand
          locale={locale}
          stickyHeader={stickyHeader}
          navbarOpen={navbarOpen}
          page={page}
          customClass={isAuctionDetailPage && stickyHeader ? css.isNotDesktop : ''}
        />
        {showStickyMobileHeader &&
          <CustomPropertyDetail
            dataLayer={handleDataLayer}
            customDropDown={customDropDown}
            setCustomDropDown={setCustomDropDown}
            auctionDetails={auctionDetails}
            getAuctionDetails={getAuctionDetails}
            setIsSignInClick={setIsSignInClick}
          />
        }
        <SecondaryNav
          stickyHeader={stickyHeader}
          setRevampNavOpen={setRevampNavOpen}
          showStickyMobileHeader={showStickyMobileHeader}
        />
        {isSideNavProfileAvailable() &&
          <ProfileSideMenu.default
            handleDataLayerForNav={handleDataLayerForNav} 
            setRevampNavOpen={setRevampNavOpen}
            setIsSignInClick={setIsSignInClick}
            isMobile={isMobileOnly}
            pageType={page}
            statusTag={statusTag}
          /> 
        }
      </div>
    </header>
      {(isLoginPopUpAvailable()) &&
        <LoginPopup.default
          login_type={'profile'}  
          pageType={page}
          onClose={onScreenClose}
          popuptype={'login'}
          onSuccessLogin={onSuccessLogin}
          data={null}
          handleDataLayerCommon={handleDataLayerCommon}
          processType={false}
          signUpPopup={false}
          secondPopUp={false}
        />
    }
    </>
  )
}

export default memo(Header)
