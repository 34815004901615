import React from 'react'

const IconInstagram = ({ width, height, fillColor, iconTitle }) => {
  return (
    <svg width={width || 24} height={height || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>{iconTitle}</title>
      <path
        d='M12 2.982C14.937 2.982 15.285 2.993 16.445 3.046C17.1424 3.05412 17.8332 3.18233 18.487 3.425C18.965 3.60111 19.3973 3.88237 19.752 4.248C20.1176 4.60269 20.3989 5.035 20.575 5.513C20.8177 6.16685 20.9459 6.85762 20.954 7.555C21.007 8.715 21.018 9.063 21.018 12C21.018 14.937 21.007 15.285 20.954 16.445C20.9459 17.1424 20.8177 17.8332 20.575 18.487C20.3919 18.9615 20.1116 19.3924 19.752 19.752C19.3924 20.1116 18.9615 20.3919 18.487 20.575C17.8332 20.8177 17.1424 20.9459 16.445 20.954C15.285 21.007 14.937 21.018 12 21.018C9.063 21.018 8.715 21.007 7.555 20.954C6.85762 20.9459 6.16685 20.8177 5.513 20.575C5.035 20.3989 4.60269 20.1176 4.248 19.752C3.88237 19.3973 3.60111 18.965 3.425 18.487C3.18233 17.8332 3.05412 17.1424 3.046 16.445C2.993 15.285 2.982 14.937 2.982 12C2.982 9.063 2.993 8.715 3.046 7.555C3.05412 6.85762 3.18233 6.16685 3.425 5.513C3.60111 5.035 3.88237 4.60269 4.248 4.248C4.60269 3.88237 5.035 3.60111 5.513 3.425C6.16685 3.18233 6.85762 3.05412 7.555 3.046C8.715 2.993 9.063 2.982 12 2.982ZM12 1C9.013 1 8.638 1.013 7.465 1.066C6.55258 1.08486 5.6499 1.25762 4.795 1.577C4.06355 1.86017 3.3994 2.29319 2.84521 2.84824C2.29102 3.40329 1.85904 4.06811 1.577 4.8C1.25762 5.6549 1.08486 6.55758 1.066 7.47C1.013 8.638 1 9.013 1 12C1 14.987 1.013 15.362 1.066 16.535C1.08486 17.4474 1.25762 18.3501 1.577 19.205C1.86017 19.9365 2.29319 20.6006 2.84824 21.1548C3.40329 21.709 4.06811 22.141 4.8 22.423C5.6549 22.7424 6.55758 22.9151 7.47 22.934C8.638 22.987 9.013 23 12 23C14.987 23 15.362 22.987 16.535 22.934C17.4474 22.9151 18.3501 22.7424 19.205 22.423C19.9365 22.1398 20.6006 21.7068 21.1548 21.1518C21.709 20.5967 22.141 19.9319 22.423 19.2C22.7424 18.3451 22.9151 17.4424 22.934 16.53C22.987 15.362 23 14.987 23 12C23 9.013 22.987 8.638 22.934 7.465C22.9151 6.55258 22.7424 5.6499 22.423 4.795C22.1398 4.06355 21.7068 3.3994 21.1518 2.84521C20.5967 2.29102 19.9319 1.85904 19.2 1.577C18.3451 1.25762 17.4424 1.08486 16.53 1.066C15.362 1.013 14.987 1 12 1Z'
        fill={fillColor || '#7C7E83'}
      />
      <path
        d='M11.9996 6.35156C10.8823 6.35156 9.79015 6.68287 8.86117 7.30359C7.9322 7.92431 7.20815 8.80656 6.78059 9.83878C6.35303 10.871 6.24116 12.0068 6.45913 13.1026C6.6771 14.1984 7.21512 15.205 8.00514 15.995C8.79517 16.785 9.80172 17.3231 10.8975 17.541C11.9933 17.759 13.1291 17.6471 14.1614 17.2196C15.1936 16.792 16.0758 16.068 16.6966 15.139C17.3173 14.21 17.6486 13.1178 17.6486 12.0006C17.6486 10.5024 17.0534 9.06551 15.994 8.00612C14.9346 6.94672 13.4978 6.35156 11.9996 6.35156ZM11.9996 15.6676C11.2743 15.6676 10.5653 15.4525 9.96231 15.0496C9.35928 14.6466 8.88927 14.0739 8.61172 13.4039C8.33418 12.7338 8.26156 11.9965 8.40305 11.2852C8.54454 10.5738 8.89379 9.92044 9.40663 9.4076C9.91947 8.89476 10.5729 8.54552 11.2842 8.40402C11.9955 8.26253 12.7328 8.33515 13.4029 8.6127C14.0729 8.89024 14.6457 9.36025 15.0486 9.96329C15.4515 10.5663 15.6666 11.2753 15.6666 12.0006C15.6666 12.9731 15.2802 13.9058 14.5925 14.5935C13.9049 15.2812 12.9721 15.6676 11.9996 15.6676Z'
        fill={fillColor || '#7C7E83'}
      />
      <path
        d='M17.8718 7.44859C18.6008 7.44859 19.1918 6.85761 19.1918 6.12859C19.1918 5.39958 18.6008 4.80859 17.8718 4.80859C17.1427 4.80859 16.5518 5.39958 16.5518 6.12859C16.5518 6.85761 17.1427 7.44859 17.8718 7.44859Z'
        fill={fillColor || '#7C7E83'}
      />
    </svg>
  )
}

export default IconInstagram
