import React from 'react'

const IconYoutube = ({ width, height, fillColor, iconTitle }) => {
  return (
    <svg width={width || 24} height={height || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>{iconTitle}</title>
      <path
        d='M23.8 7.19961C23.8 7.19961 23.6 5.49961 22.8 4.79961C21.9 3.79961 20.9 3.79961 20.4 3.79961C17 3.59961 12 3.59961 12 3.59961C12 3.59961 7 3.59961 3.6 3.79961C3.1 3.89961 2.1 3.89961 1.2 4.79961C0.5 5.49961 0.2 7.19961 0.2 7.19961C0.2 7.19961 0 9.09961 0 11.0996V12.8996C0 14.7996 0.2 16.7996 0.2 16.7996C0.2 16.7996 0.4 18.4996 1.2 19.1996C2.1 20.1996 3.3 20.0996 3.8 20.1996C5.7 20.3996 12 20.3996 12 20.3996C12 20.3996 17 20.3996 20.4 20.0996C20.9 19.9996 21.9 19.9996 22.8 19.0996C23.5 18.3996 23.8 16.6996 23.8 16.6996C23.8 16.6996 24 14.7996 24 12.7996V10.9996C24 9.09961 23.8 7.19961 23.8 7.19961ZM9.5 15.0996V8.39961L16 11.7996L9.5 15.0996Z'
        fill={fillColor || '#7C7E83'}
      />
    </svg>
  )
}

export default IconYoutube
