import React, { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { initGA, logPageView } from '@/utils/analytics'
import Head from 'next/head'
import Header from '@/components/Common/SEOHeader/Header'
import Footer from '@/components/Common/Footer/Footer'
import useTranslation from 'next-translate/useTranslation'
import { updateRouterPath } from '@/utils/utils'
import headerFooterAr from './headerFooterAr.json'
import headerFooterEn from './headerFooterEn.json'

const Layout = ({
  metaData,
  children,
  title,
  headerCities,
  footerLinks,
  keywords,
  description,
  currentPage,
  showHeader = true,
  locale,
  auctionDetails,
  getAuctionDetails = null,
}) => {
  const { t } = useTranslation('translations-auction')
  const router = useRouter()
  const headerRef = useRef()
  const prevStickyHeaderRef = useRef(false);
  const [customDropDown, setCustomDropDown] = useState(false)
  const headerLinks = locale == 'ar' ? headerFooterAr?.navigationLinks : headerFooterEn?.navigationLinks
  const footerLinksData = locale == 'ar' ? headerFooterAr?.footerLinks : headerFooterEn?.footerLinks
  const onScroll = () => {
    if (!headerRef.current) {
      return
    }
    const top = headerRef.current.getBoundingClientRect().top
    if (top < 88) {
      setCustomDropDown(false)
    }
    prevStickyHeaderRef.current = scrollY >= 100;
  }
  
  useEffect(() => {
    updateRouterPath(router) // captures all the router paths
  }, [router.events, router.query])
  
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])
  
  useEffect(() => {
    if (typeof window === undefined) return

    const handleRouteChange = (url) => {
      logPageView(url)
    }
    if (!window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      if (typeof window === undefined) return
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const getResolvedFooterLinks = () => {
    if (footerLinks === undefined || (typeof footerLinks === 'object' && Object.keys(footerLinks).length === 0)) {
      return footerLinksData;
    } 
    return footerLinks;
  }

  return (
    <>
      {/* Layout Head  */}
      <Head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0' />
        <title>{currentPage == 'auction-home-page' ? t('Home.SEARCH_HEADING') : metaData?.meta_title || title || t('Home.SEARCH_HEADING')}</title>
        <meta name='keyword' content={keywords} />
        <meta name='description' content={description || t('AboutUS.ABOUT_TITLE')} />
        <meta name='msapplication-tilecolor' content='#253d97' />
        <meta name='msapplication-tileimage' content='/favicon/ms-icon-144x144.png' />
        <link rel='shortcut icon' href='/favicon/ms-icon-144x144.png' />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
        <link rel="apple-touch-icon" sizes="180x180" href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-180.png`}></link>
        <link rel="apple-touch-icon" sizes="120x120" href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-120.png`}></link>
        <link rel="apple-touch-icon" sizes="167x167" href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-167.png`}></link>
        <link rel="apple-touch-icon" sizes="152×152" href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/apple-touch-icon-152.png`}></link>
        {currentPage == 'home' ||
          (currentPage == 'mapSearch' && (
            <link
              defer
              rel='preconnect dns-prefetch'
              href={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY}&v=3.31`}
            />
          ))}
        <link rel='preconnect' href={process.env.NEXT_PUBLIC_BASE_FILE_URL} />
        <link rel='preconnect' href={process.env.NEXT_PUBLIC_CDN_BASE_FILE_URL} />
      </Head>
      {/* Layout Header Seaction */}
      
      {showHeader && (
        <Header
          headerCities={headerCities === undefined || 
            (typeof headerCities === 'object' && Object.keys(headerCities).length === 0) ? headerLinks : headerCities}
          page={currentPage}
          locale={locale}
          stickyHeader={prevStickyHeaderRef?.current}
          metaData={metaData || null}
          customDropDown={customDropDown}
          setCustomDropDown={setCustomDropDown}
          auctionDetails={auctionDetails}
          getAuctionDetails={getAuctionDetails}
        />
      )}
      {/* Layout Main Seaction  */}
      <div ref={headerRef}>{children}</div>
      {/* Layout Footer Seaction */}
      {footerLinks ? (
        <Footer
          footerLinks={getResolvedFooterLinks()}
          page={currentPage}
          locale={locale}
        />
      ) : null}
    </>
  )
}

export default Layout
