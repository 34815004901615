import React from 'react'

const IconLinkedIn = ({ width, height, fillColor, iconTitle }) => {
  return (
    <svg width={width || 24} height={height || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>{iconTitle}</title>
      <path
        d='M23 0H1C0.4 0 0 0.4 0 1V23C0 23.6 0.4 24 1 24H23C23.6 24 24 23.6 24 23V1C24 0.4 23.6 0 23 0ZM7.1 20.5H3.6V9H7.2V20.5H7.1ZM5.3 7.4C4.2 7.4 3.2 6.5 3.2 5.3C3.2 4.2 4.1 3.2 5.3 3.2C6.4 3.2 7.4 4.1 7.4 5.3C7.4 6.5 6.5 7.4 5.3 7.4ZM20.5 20.5H16.9V14.9C16.9 13.6 16.9 11.9 15.1 11.9C13.2 11.9 13 13.3 13 14.8V20.5H9.4V9H12.8V10.6C13.3 9.7 14.4 8.8 16.2 8.8C19.8 8.8 20.5 11.2 20.5 14.3V20.5Z'
        fill={fillColor || '#7C7E83'}
      />
    </svg>
  )
}

export default IconLinkedIn
