import React, { useContext, createContext, useState, useEffect } from 'react'
import { fetchCurrentEpoch } from '@/utils/auctionUtils'

export const AuctionContext = createContext()
export const useAuctionProvider = () => useContext(AuctionContext)

const AuctionProvider = ({ children }) => {
  const [currentEpoch, setcurrentEpoch] = useState(null)
  const [isEditPopUpOpen, setIsEditPopUpOpen] = useState(false)
  const [isMsiteWithdrawClicked, setIsWithdrawClicked] = useState(
   false )

  const getcurrentEpoch =  async() => {
    await fetchCurrentEpoch(setcurrentEpoch)
  }

  useEffect(() => {
    getcurrentEpoch()
  }, [])

  return (
    <AuctionContext.Provider
      value={{
        currentEpoch,
        setcurrentEpoch,
        isEditPopUpOpen,
        setIsEditPopUpOpen,
        isMsiteWithdrawClicked,
        setIsWithdrawClicked,
      }}
    >
      {children}
    </AuctionContext.Provider>
  )
}

export default AuctionProvider
