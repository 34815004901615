import IconCaretDown from '@/assets/icons/IconCaretDown'
import IconCaretUp from '@/assets/icons/IconCaretUp'
import { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styles from './Category.module.scss'

function Category({ category, title }) {
  const [dropdownStatus, setDropdownStatus] = useState(true)
  useEffect(() => {
    setDropdownStatus(isMobileOnly ? false : true)
  }, [])
  const listView =
    category &&
    category.map((item) => (
      <li key={item.id} className={styles.py1}>
        <a href={`${process.env.NEXT_PUBLIC_WASALTBASE_URL}${item.url}`} className={styles.fontMedium}>
          {item.title}
        </a>
      </li>
    ))

  return (
    <div className={`${styles.colLg3}`}>
      <div
        className={styles.title}
        onClick={() => {
          if (isMobileOnly) {
            setDropdownStatus(!dropdownStatus)
          }
        }}
      >
        <div className={styles.head}>{title}</div>
        <div className={`${styles.arrow}`}>
          {dropdownStatus ? (
            <IconCaretUp width='24' height='24' strokeColor='currentcolor' />
          ) : (
            <IconCaretDown width='24' height='24' strokeColor='currentcolor' fillColor='none' />
          )}
        </div>
      </div>
      {dropdownStatus && <ul className={`${styles.listInline}`}>{listView}</ul>}
    </div>
  )
}
export default Category
