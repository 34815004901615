import React from 'react'

import css from './SocialLinks.module.scss'
import IconInstagram from '@/assets/icons/IconInstagram'
import IconFacebook from '@/assets/icons/IconFacebook'
import IconYoutube from '@/assets/icons/IconYoutube'
import IconTwitter from '@/assets/icons/IconTwitter'
import IconLinkedIn from '@/assets/icons/IconLinkedIn'
import IconoGraphy from '@/components/Common/IconoGraphy'
import { pushDataLayerEventHandler } from '@/utils/utils'

const SocialLinks = ({ lang, page }) => {
  const socialLinksClickHandler = (linkName) => {
    pushDataLayerEventHandler(window, lang, 'social_link_clicked', page, { name: linkName })
  }

  return (
    <div className={css.social}>
      <ul className={css.social__list}>
        <li className={css.social__item}>
          <a
            href='https://instagram.com/wasaltofficial'
            target='_blank'
            title='instagram'
            className={css.social__link}
            onClick={() => {
              socialLinksClickHandler('instagram')
            }}
          >
            <IconInstagram title='instagram' />
          </a>
        </li>
        <li className={css.social__item}>
          <a
            href={`${
              lang === 'en' ? 'https://www.tiktok.com/@wasalt?lang=en' : 'https://www.tiktok.com/@wasalt?lang=ar'
            }`}
            target='_blank'
            title='tiktok'
            className={css.social__link}
            onClick={() => {
              socialLinksClickHandler('tiktok')
            }}
          >
           <IconoGraphy iconClass={'icon-tiktok'} iconColor={'color_gray'}></IconoGraphy>
          </a>
        </li>
        <li className={css.social__item}>
          <a
            href='https://www.facebook.com/WasaltOfficial'
            target='_blank'
            title='facebook'
            className={css.social__link}
            onClick={() => {
              socialLinksClickHandler('facebook')
            }}
          >
            <IconFacebook title='facebook' />
          </a>
        </li>
        <li className={css.social__item}>
          <a
            href='https://www.linkedin.com/company/42315705/admin/'
            target='_blank'
            title='linkedin'
            className={css.social__link}
            onClick={() => {
              socialLinksClickHandler('linkedin')
            }}
          >
            <IconLinkedIn title='linkedin' />
          </a>
        </li>
        <li className={css.social__item}>
          <a
            href='https://www.youtube.com/channel/UCwV9YXTxwCNu-XhmsmWKJFA/featured'
            target='_blank'
            title='youtube'
            className={css.social__link}
            onClick={() => {
              socialLinksClickHandler('youtube')
            }}
          >
            <IconYoutube title='youtube' />
          </a>
        </li>
        <li className={css.social__item}>
          <a
            href='https://twitter.com/WasaltOfficial'
            target='_blank'
            title='twitter'
            className={css.social__link}
            onClick={() => {
              socialLinksClickHandler('twitter')
            }}
          >
            <IconTwitter title='twitter' />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default SocialLinks
